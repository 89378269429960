<script>
import { ArrowUpIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Index-studio component
 */
export default {
  data() {
    return {
      projects: [
        {
          id: 1,
          image: "images/work/1.jpg",
          title: "Iphone mockup",
          subtext: "Branding",
        },
        {
          id: 2,
          image: "images/work/2.jpg",
          title: "Mockup Collection",
          subtext: "Mockup",
        },
        {
          id: 3,
          image: "images/work/3.jpg",
          title: "Abstract images",
          subtext: "Abstract",
        },
        {
          id: 4,
          image: "images/work/4.jpg",
          title: "Yellow bg with Books",
          subtext: "Books",
        },
        {
          id: 5,
          image: "images/work/5.jpg",
          title: "Company V-card",
          subtext: "V-card",
        },
        {
          id: 6,
          image: "images/work/6.jpg",
          title: "Mockup box with paints",
          subtext: "Photography",
        },
      ],
    };
  },
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
  },
};
</script>

<template>
  <div>

    <!-- Hero Start -->
    <section
      class=" vh-100 d-table w-100"
      style="background: url('images/3.jpg') center center"
      id="home"
    >
      <div class="container">
        <div class="row justify-content-center align-items-center pt-4 vh-100">
          <div class="col-lg-10 align-middle">
            <div class="title-heading studio-home rounded bg-white shadow mt-5 text-center" >
              <h1 style="font-family: 'Great Vibes', cursive !important; font-weight: 100 !important;" >Based on Actual Events</h1>
              <h4 class="mb-3 text-center text-muted" >
                AUTHORIZATION REQUIRED
              </h4>
              <div class="mt-4 text-center">
                <a
                  href="https://magnificent.holdings"
                  target="__blank"
                  class=" mt-2 mr-2"
                  >Holdings</a
                >
                <a
                  href="https://magnificent.capital"
                  target="__blank"
                  class=" mt-2"
                  >
                  Capital</a
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->


    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
</style>
